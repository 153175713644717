import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { url: String, interval: Number, deploymentId: Number };

  connect() {
    this.loadPartial();
    this.startRefreshing();
  }

  disconnect() {
    this.stopRefreshing();
  }

  async startRefreshing() {
    this.interval = setInterval(async () => {
      if (await this.shouldStopRefreshing()) {
        this.stopRefreshing();
        return;
      }
      this.loadPartial();
    }, this.intervalValue || 5000); // Default to 5 seconds if intervalValue is not provided
  }

  stopRefreshing() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  async shouldStopRefreshing() {
    const response = await fetch(
      `/crewai_plus/deployments/${this.deploymentIdValue}/check_provision_status.json`
    );
    const data = await response.json();
    return data.state !== "In Progress";
  }

  loadPartial() {
    const logsContainer = this.element.querySelector("#logs-container");
    if (!logsContainer) return;
    const scrollTop = logsContainer?.scrollTop ?? 0;
    const isAtTop = scrollTop === 0;
    const isAtBottom =
      Math.abs(
        logsContainer.scrollHeight - logsContainer.clientHeight - scrollTop
      ) < 1;

    fetch(this.urlValue, {
      headers: { Accept: "text/html" },
    })
      .then((response) => response.text())
      .then((html) => {
        this.element.innerHTML = html;

        const newLogsContainer = this.element.querySelector("#logs-container");
        if (newLogsContainer) {
          if (isAtTop || isAtBottom) {
            newLogsContainer.scrollTop = newLogsContainer.scrollHeight;
          } else {
            newLogsContainer.scrollTop = scrollTop;
          }
        }
      })
      .catch((error) => console.error("Error loading partial:", error));
  }
}
